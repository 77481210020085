/**
 * @license
 *
 * Font Family: Clash Grotesk
 * Designed by: Indian Type Foundry
 * URL: https://www.fontshare.com/fonts/clash-grotesk
 * Â© 2022 Indian Type Foundry
 *
 * Font Styles:
 * Clash Grotesk Variable(Variable font)
 * Clash Grotesk Extralight
 * Clash Grotesk Light
 * Clash Grotesk Regular
 * Clash Grotesk Medium
 * Clash Grotesk Semibold
 * Clash Grotesk Bold
 *
*/

/**
* This is a variable font
* You can controll variable axes as shown below:
* font-variation-settings: 'wght' 700.0;
*
* available axes:

* 'wght' (range from 200.0 to 700.0)

*/

/* Clash Grotesk */

@font-face {
  font-family: 'Clash Grotesk';
  src: url('assets/fonts/clash-grotesk/ClashGrotesk-Light.ttf')
    format('truetype');
  font-weight: 300;
  font-display: fallback;
  font-style: normal;
}

@font-face {
  font-family: 'Clash Grotesk';
  src: url('assets/fonts/clash-grotesk/ClashGrotesk-Regular.ttf')
    format('truetype');
  font-weight: 400;
  font-display: fallback;
  font-style: normal;
}

@font-face {
  font-family: 'Clash Grotesk';
  src: url('assets/fonts/clash-grotesk/ClashGrotesk-Medium.ttf')
    format('truetype');
  font-weight: 500;
  font-display: fallback;
  font-style: normal;
}

@font-face {
  font-family: 'Clash Grotesk';
  src: url('assets/fonts/clash-grotesk/ClashGrotesk-Semibold.ttf')
    format('truetype');
  font-weight: 600;
  font-display: fallback;
  font-style: normal;
}

@font-face {
  font-family: 'Clash Grotesk';
  src: url('assets/fonts/clash-grotesk/ClashGrotesk-Bold.ttf')
    format('truetype');
  font-weight: 700;
  font-display: fallback;
  font-style: normal;
}
