.react-calendar {
  width: 100%;
  font-family: 'Plus Jakarta Sans';
  border: 1px solid #e4e7ec;
  background-color: #fff;
  height: 'max-content';
  border-radius: 8px;
  padding: 30px;
}

.react-calendar__navigation {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50px;
}

.react-calendar__navigation__label {
  pointer-events: none;
}

.react-calendar__month-view__weekdays {
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 40px;
  margin-bottom: 20px;
}
.react-calendar__month-view__weekdays__weekday {
  flex: unset !important;
}

.react-calendar__month-view__weekdays__weekday abbr {
  text-decoration: none !important;
}

.react-calendar__month-view__days {
  padding: 0;
}

.react-calendar__navigation__next2-button,
.react-calendar__navigation__prev2-button {
  display: none;
}

.react-calendar__month-view__days__day--neighboringMonth {
  color: #cdcdcd;
}

.react-calendar__tile {
  height: 50px;
  border-radius: 50%;
  position: relative;
}

.react-calendar__tile--active {
  background-color: #1b1d3e;
  color: #fff;
}

.react-calendar__tile--active span {
  background-color: #fff;
}

.react-calendar__tile--now {
  background-color: #f2f4f7;
  color: #1b1d3e;
}
